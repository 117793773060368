.consent-manager {
  position: fixed;
  bottom: -100;
  width: 100%;
  height: 0;
  border-radius: 8px;
  background: #1f2937;
  transition: height 1s;
  z-index: 1022;
  padding: 30px 20px;
  color: #fff;
}

.consent-manager.open {
  height: max-content;
  z-index: 1022;
  bottom: 0;
}

body.consent-manager-open {
  overflow: hidden;
}

body.consent-manager-open::after {
  content: '';
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1021;
}

.consent-manager.open .title {
  font-size: xx-large;
  text-align: center;
  margin-bottom: 16px;
}

.consent-manager.open p {
  text-align: center;
}

.consent-manager.open .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  gap: 12px;
}

.consent-manager.open .checklists {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.consent-manager.open .checklists div label {
  margin-left: 6px;
}

.consent-manager.open .checklists div input:checked {
  border-radius: 4px;
}

.consent-manager.open .checklists div input:not(:checked) {
  background-color: #374151;
  border: 1px solid #4b5563;
  border-radius: 4px;
}
