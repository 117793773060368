/* latin-ext */
@font-face {
  font-family: '__Figtree_7d5794';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/03407990841cab7a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Figtree_7d5794';
  font-style: normal;
  font-weight: 300 900;
  font-display: swap;
  src: url(/_next/static/media/968c529aef8f703d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Figtree_Fallback_7d5794';src: local("Arial");ascent-override: 93.60%;descent-override: 24.63%;line-gap-override: 0.00%;size-adjust: 101.49%
}.__className_7d5794 {font-family: '__Figtree_7d5794', '__Figtree_Fallback_7d5794';font-style: normal
}

.consent-manager {
  position: fixed;
  bottom: -100;
  width: 100%;
  height: 0;
  border-radius: 8px;
  background: #1f2937;
  transition: height 1s;
  z-index: 1022;
  padding: 30px 20px;
  color: #fff;
}

.consent-manager.open {
  height: -moz-max-content;
  height: max-content;
  z-index: 1022;
  bottom: 0;
}

body.consent-manager-open {
  overflow: hidden;
}

body.consent-manager-open::after {
  content: '';
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1021;
}

.consent-manager.open .title {
  font-size: xx-large;
  text-align: center;
  margin-bottom: 16px;
}

.consent-manager.open p {
  text-align: center;
}

.consent-manager.open .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  gap: 12px;
}

.consent-manager.open .checklists {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.consent-manager.open .checklists div label {
  margin-left: 6px;
}

.consent-manager.open .checklists div input:checked {
  border-radius: 4px;
}

.consent-manager.open .checklists div input:not(:checked) {
  background-color: #374151;
  border: 1px solid #4b5563;
  border-radius: 4px;
}

